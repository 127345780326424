import { Layout } from 'core-ui';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';
import SideMenu from './components/SideMenu';

const MainLayout = () => {
  return (
    <Layout
      headerContent={<Header />}
      headerStyle={{
        zIndex: 2000
      }}
      logoContent={<Logo />}
      defaultSiderWidth={220}
      hideCollapser
      contentStyle={{
        overflow: 'auto'
      }}
      customSider={<SideMenu />}
    >
      <Outlet />
    </Layout>
  );
};

export default MainLayout;
